import { LinkProps, Flex, Button, Box, Text, PopoverTrigger, Popover, PopoverContent } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import NextLink from 'next/link'

import { useHydrationFriendlyAsPath } from '@miimosa/common'
import type { CountryCode } from '@miimosa/common/types'
import { Icon } from '@miimosa/design-system'
import * as Flags from '@miimosa/design-system/components/icons/flags'
import { usePlatform } from '@miimosa/design-system/lib/hooks'

const PlatformFlag = ({ country }: { country: CountryCode }) => {
  const Flag = Flags[country]
  return <Flag />
}

const PlatformNameAndFlag = ({ country }: { country: CountryCode }) => {
  const { t } = useTranslation()
  const Flag = Flags[country]
  return (
    <>
      <Flag />
      <Text ml={2} color="black">
        {t(`countries.${country}`)}
      </Text>
    </>
  )
}

interface PlatformLinkProps extends LinkProps {
  country: CountryCode
  route: string
}

const PlatformLink = ({ country, route }: PlatformLinkProps) => {
  return (
    <NextLink locale={country === 'fr' ? 'fr' : 'be'} href={route}>
      <Flex
        direction="row"
        py="4px"
        _hover={{
          color: 'dark_green',
          fontWeight: 700,
        }}
        sx={{
          '&:hover': {
            '.plathigh': {
              visibility: 'visible',
            },
          },
        }}
      >
        <Box w="4px" mr="10px" bg="dark_green" visibility="hidden" borderLeftRadius="4px" className="plathigh" />
        <PlatformNameAndFlag country={country} />
      </Flex>
    </NextLink>
  )
}

const PlatformSelect = ({ isMobile, mobileNavIsOpen }: { isMobile?: boolean; mobileNavIsOpen?: boolean }) => {
  const { platform } = usePlatform()
  const asPath = useHydrationFriendlyAsPath()

  return (
    <Popover closeOnBlur={true} margin-top="-3px" gutter={16} placement="bottom-end">
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button
              variant="ghost"
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              p="6px"
              name="platformButton"
              aria-label="Platform"
            >
              <PlatformFlag country={platform} />
              <Icon
                name="ChevronDown"
                color={isMobile && mobileNavIsOpen ? 'white' : 'dark_green'}
                size="6xs"
                transition="transform 0.2s ease-out"
                transform={isOpen ? 'rotate(180deg)' : undefined}
                mx={2}
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent border={0}>
            <Flex direction="column" w="full" px="6px" py="4px" bg="white">
              <PlatformLink country="fr" route={asPath} />
              <PlatformLink country="be" route={asPath} />
            </Flex>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
export default PlatformSelect
